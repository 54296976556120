<template>
  <dashboard>
    <div class="rrjetiNpostomatDiv">
      <div class="container  animate__animated animate__fadeInRight">
        <div class="row">
          <div class="col col-12">
            <div class="title">
              <h1>Rrjeti i N'Postomateve</h1>
              <hr />
            </div>
          </div>

          <!-- Npostomat network body -->
          <div
            class="col col-12 col-lg-4 col-md-6"
            v-for="(npostomat, index) in npostomatNetwork"
            :key="index.npostomat"
          >
            <div class="mainBody">
              <div class="imageBody">
                <img :src="npostomat.image" alt="" />
              </div>

              <div class="informations">
                <div class="flexDiv">
                  <div class="dsc">
                    <h1>Emri:</h1>
                    <p>{{ npostomat.name }}</p>
                  </div>

                  <div class="dsc">
                    <h1>Pseudonimi:</h1>
                    <p>{{ npostomat.nickname }}</p>
                  </div>
                </div>

                <div class="dsc">
                  <h1>Qyteti:</h1>
                  <p>{{ npostomat.city }}</p>
                </div>

                <div class="dsc">
                  <h1>Hapur:</h1>
                  <p>{{ npostomat.open }}</p>
                </div>

                <div class="btnMore">
                  <a :href="npostomat.link" target="_blank"
                    ><button>
                      <span data-feather="info"></span> Më shumë detaje!
                    </button></a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Npostomat network body end -->
        </div>
      </div>
    </div>
  </dashboard>
</template>

<script>
import Dashboard from "../DashHome/dashboard.vue";
export default {
  components: {
    Dashboard,
  },
  data() {
    return {
      npostomatNetwork: [
        {
          image: require("../../assets/Images/plocation1.jpg"),
          name: "P-ENLLC",
          nickname: "NPOST",
          city: "Prishtinë - 10000",
          open: "09:00AM deri në 17:00PM",
          link: "https://nposta.com/lista-npostomateve/",
        },
        {
          image: require("../../assets/Images/plocation1.jpg"),
          name: "P-ENLLC",
          nickname: "NPOST",
          city: "Prishtinë - 10000",
          open: "09:00AM deri në 17:00PM",
          link: "https://nposta.com/lista-npostomateve/",
        },
        {
          image: require("../../assets/Images/plocation1.jpg"),
          name: "P-ENLLC",
          nickname: "NPOST",
          city: "Prishtinë - 10000",
          open: "09:00AM deri në 17:00PM",
          link: "https://nposta.com/lista-npostomateve/",
        },
      ],
    };
  },
};
</script>
